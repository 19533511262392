@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

@layer base {
  :root {
    --primary: #1E1E1F;
    --secondary: #FFFFFF;
    --background: #FFFFFF;
    --text: #1E1E1F;
    --gray: #ABABAB;
    --card: #F8F8F8;
    --red: #FF005C;
    --white: #FFFFFF;
    --black: #1E1E1F;
    --overlay: #000;
    --green: #00D95F;
    --yellow: #cbaf23;
    --border: #e2e2e2;
  }

  .dark {
    --primary: #FFFFFF;
    --secondary: #1E1E1F;
    --background: #1E1E1F;
    --text: #FFFFFF;
    --gray: #ABABAB;
    --card: #161616;
    --red: #FF005C;
    --white: #FFFFFF;
    --black: #1E1E1F;
    --overlay: #000;
    --green: #00D95F;
    --yellow: #cbaf23;
    --border: #363636;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    @apply dark;
  }
}

body {
  background-color: var(--background);
  color: var(--text);
  font-family: "Sora", sans-serif;
  font-optical-sizing: auto;
}